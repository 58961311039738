import { TFunction } from "i18next";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { FormService } from '../../form/services/FormService';
import { AdminDiagramService } from "../services/AdminDiagramService";
import { Dropdown } from "primereact/dropdown";
import { Diagram } from "../../form/models/Diagram";
import { TranslationService } from "../../form/services/TranslationService";
import FormDiagram from "../../form/components/FormDiagram";
import { DiagramComment } from "../../form/models/DiagramComment";
import { AdminDiagramCommentService } from "../services/AdminDiagramCommentService";
import { DiagramDot } from "../../form/models/DiagramDot";

interface Props extends WithTranslation {
}

interface States {
  diagram: Diagram
  allDiagrams: Diagram[]
  allComments: DiagramComment[]
  prismicKeys: string[]
}

class AdminEditorDiagram extends React.Component<Props, States> {
  private readonly fService: FormService;
  private readonly diagramService: AdminDiagramService;
  private readonly translationService: TranslationService
  private readonly diagramCommentService: AdminDiagramCommentService
  private toast: Toast | null;
  private t: TFunction;

  constructor(props: Props) {
    super(props);
    this.fService = new FormService();
    this.diagramService = new AdminDiagramService();
    this.diagramCommentService = new AdminDiagramCommentService()
    this.translationService = new TranslationService()
    this.t = this.props.t;
    this.toast = null;

    this.state = {
      diagram: {
        name: "",
        imageName: "",
        dots: [],
        legends: [],
        imgSize: {
          width: 0,
          height: 0
        }
      },
      allDiagrams: [],
      allComments: [],
      prismicKeys: []
    }


  }

  componentDidMount(): void {
    this._getDiagrams()
    this._getPrismicKeys()
    this._getComments()
  }

  private _getDiagrams() {
    this.diagramService.getAllDiagram()
      .then((r) => {
        this.setState({
          allDiagrams: r.data
        })
      })
  }

  private _getPrismicKeys() {
    this.translationService.get("withoutCommon/fr")
      .then((r) => {
        const keysString: string[] = []
        for (let i = 0; i < Object.keys(r.data).length; i++) {
          keysString.push(Object.keys(r.data)[i]);
        }
        this.setState({
          prismicKeys: keysString
        })
      })
  }

  private _getComments() {
    this.diagramCommentService.getMany()
      .then((r) => {
        this.setState({
          allComments: r.data
        })
      })
  }

  handleSubmit() {
    if (this.state.diagram.name !== "" && this.state.diagram.imageName !== "") {
      this.diagramService.updateOne(this.state.diagram)
        .then(() => {
          this.toast?.show({
            severity: SeverityEnum.SUCCESS,
            detail: this.fService.t(this.t, "ui_admin_editor_diagram_toast_success", false),
          });
          this.setState({
            diagram: {
              name: "",
              imageName: "",
              dots: [],
              legends: [],
              imgSize: {
                width: 0,
                height: 0
              }
            }
          })
          this._getDiagrams()
        })
        .catch((err: any) => {
          const message = err?.response?.data?.message;
          this.toast?.show({
            severity: SeverityEnum.ERROR,
            detail: `${this.fService.t(this.t, "ui_admin_editor_diagram_toast_error", false)} : ${message}`,
          });
        });
    }
    else {
      this.toast?.show({
        severity: SeverityEnum.ERROR,
        detail: `${this.fService.t(this.t, "ui_admin_editor_diagram_toast_error", false)}`,
      });
    }
  }

  render() {
    return (
      <Card title={this.fService.t(this.t, "ui_admin_editor_diagram_title", false)}>
        <Toast ref={(el) => (this.toast = el)} />
        <Dropdown className="p-col-12 p-md-6"
          value={this.state.diagram ? this.state.diagram.name : ""}
          options={this.state.allDiagrams.map(e => e.name)}
          onChange={(e) => {
            const currentDiagram = this.state.allDiagrams.find(d => d.name === e.value)
            if (currentDiagram) {
              const copy = Object.assign({}, currentDiagram)
              this.setState({
                diagram: copy
              })
            }
            else {
              const diagram = this.state.diagram as any
              diagram.name = e.value
              this.setState({
                diagram
              })
            }
          }}
          placeholder="Diagram"
          editable
        />
        <Dropdown className="p-col-12 p-md-6"
          value={this.state.diagram ? this.state.diagram.imageName : ""}
          options={this.state.prismicKeys}
          onChange={(e) => {
            const diagram = this.state.diagram as any
            if (diagram) {
              diagram.imageName = e.value
              this.setState({
                diagram
              })
            }
          }}
          placeholder="Image"
        />
        <div className="p-col-12 p-d-flex p-jc-center">
          <Button
            label="Save new diagram"
            icon="pi pi-file"
            className="p-button p-button-info  p-m-2"
            onClick={() => this.handleSubmit()}
            disabled={this.state.allDiagrams.map(e => e.name).includes(this.state.diagram?.name)} />
          <Button
            label="Update diagram"
            icon="pi pi-check"
            className="p-button p-button-info  p-m-2 "
            onClick={() => this.handleSubmit()}
            disabled={!this.state.allDiagrams.map(e => e.name).includes(this.state.diagram?.name)}
          />
        </div>
        {this.state.diagram && (
          <div className="p-col-12 p-md-12">
            <FormDiagram diagram={this.state.diagram}
              comments={this.state.allComments}
              editable={true}
              allPrismicKeys={this.state.prismicKeys}
              mouseUp={(dots: DiagramDot[], width: number, height: number) => {
                console.log(dots)
                const diagram = this.state.diagram as any
                diagram.dots = dots
                diagram.imgSize = {
                  width,
                  height
                }
                this.setState({
                  diagram: diagram
                })
              }}
              onCommentSave={(comment: DiagramComment) => {
                this.diagramCommentService.updateComment(comment)
                  .then(() => {
                    this.toast?.show({
                      severity: SeverityEnum.SUCCESS,
                      detail: this.fService.t(this.t, "ui_admin_editor_diagram_comment_toast_success", false),
                    });
                    this._getComments()
                  })
                  .catch((err: any) => {
                    const message = err?.response?.data?.message;
                    this.toast?.show({
                      severity: SeverityEnum.ERROR,
                      detail: `${this.fService.t(this.t, "ui_admin_editor_diagram_comment_toast_error", false)} : ${message}`,
                    });
                  })
              }}
              onCommentDelete={(number: number) => {
                this.diagramCommentService.deleteComment(number)
                  .then(() => {
                    this.toast?.show({
                      severity: SeverityEnum.SUCCESS,
                      detail: this.fService.t(this.t, "ui_admin_editor_diagram_comment_delete_toast_success", false),
                    });
                    this._getComments()
                  })
                  .catch((err: any) => {
                    const message = err?.response?.data?.message;
                    this.toast?.show({
                      severity: SeverityEnum.ERROR,
                      detail: `${this.fService.t(this.t, "ui_admin_editor_diagram_comment_delete_toast_error", false)} : ${message}`,
                    });
                  })
              }}
            ></FormDiagram>
          </div>
        )}

      </Card>
    )
  }
}

export default withTranslation()(AdminEditorDiagram);
