import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import './DiagramEditor.scss';
import DiagramEditorSideBar from "./component/DiagramEditorSideBar"
import { Diagram } from "../../form/models/Diagram";
import { DiagramComment } from "../../form/models/DiagramComment";
import { FormService } from "../../form/services/FormService";
import { TranslationService } from "../../form/services/TranslationService";
import { AdminDiagramService } from "../../admin/services/AdminDiagramService";
import { AdminDiagramCommentService } from "../../admin/services/AdminDiagramCommentService";
import { TFunction } from "i18next";
import { Toast } from "primereact/toast";
import FormDiagram from "../../form/components/FormDiagram";
import { DiagramDot } from "../../form/models/DiagramDot";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { DiagramLegend } from "../../form/models/DiagramLegend";

interface Props extends WithTranslation {
}

interface States {
    diagram: Diagram
    allDiagrams: Diagram[]
    allComments: DiagramComment[]
    prismicKeys: string[]
    showDeleteModal: boolean
}

class DiagramEditor extends React.Component<Props, States> {
    private readonly fService: FormService;
    private readonly diagramService: AdminDiagramService;
    private readonly translationService: TranslationService
    private readonly diagramCommentService: AdminDiagramCommentService
    private toast: Toast | null;
    private t: TFunction;

    private leftContents: any;
    private rightContents: any;

    constructor(props: Props) {
        super(props);
        this.fService = new FormService();
        this.diagramService = new AdminDiagramService();
        this.diagramCommentService = new AdminDiagramCommentService()
        this.translationService = new TranslationService()
        this.t = this.props.t;
        this.toast = null;
        this.state = {
            diagram: {
                name: "",
                imageName: "",
                dots: [],
                legends: [],
                imgSize: {
                    width: 0,
                    height: 0
                }
            },
            allDiagrams: [],
            allComments: [],
            prismicKeys: [],
            showDeleteModal: false
        }
    }

    componentDidMount(): void {
        this._getDiagrams()
        this._getPrismicKeys()
        this._getComments()
    }

    private _getDiagrams() {
        this.diagramService.getAllDiagram()
            .then((r) => {
                this.setState({
                    allDiagrams: r.data
                })
            })
    }

    private _getPrismicKeys() {
        this.translationService.get("withoutCommon/fr")
            .then((r) => {
                const keysString: string[] = []
                for (let i = 0; i < Object.keys(r.data).length; i++) {
                    keysString.push(Object.keys(r.data)[i]);
                }
                this.setState({
                    prismicKeys: keysString
                })
            })
    }

    private _getComments() {
        this.diagramCommentService.getMany()
            .then((r) => {
                this.setState({
                    allComments: r.data
                })
            })
    }

    render() {
        this.leftContents = (<>
            <h2>Schema editor</h2>
            <div className="toolbarCenterRequirement">
                <label style={{ marginRight: 5 }}>Name</label>
                <InputText
                    value={this.state.diagram.name}
                    onChange={(e) => this.setState({ diagram: { ...this.state.diagram, name: e.currentTarget.value } })}
                    placeholder="Schema name"
                    style={{ width: '10rem', marginRight: 5 }}
                />
                <label style={{ marginRight: 5 }}>Image</label>
                <Dropdown style={{ padding: 0 }} className="p-col-12 p-md-6"
                    value={this.state.diagram.imageName ?? ""}
                    options={this.state.prismicKeys}
                    onChange={(e) => this.setState({ diagram: { ...this.state.diagram, imageName: e.value } })}
                    placeholder="Image"
                />
            </div>
        </>)
        this.rightContents = (<>
            <div className="action">
                <Button
                    type="button"
                    icon="pi pi-download"
                    className="p-button-danger"
                    style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                    onClick={() => { }}
                />
                <Button
                    icon="pi pi-save"
                    className="p-button-danger"
                    style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
                    onClick={() => {
                        this.diagramService.updateOne(this.state.diagram)
                            .then(() => {
                                this.toast?.show({
                                    severity: SeverityEnum.SUCCESS,
                                    detail: "Diagram enregistré !",
                                });
                                this._getDiagrams()
                            })
                            .catch((err: any) => {
                                const message = err?.response?.data?.message;
                                this.toast?.show({
                                    severity: SeverityEnum.ERROR,
                                    detail: `Erreur : ${message}`,
                                });
                            })
                    }}
                />
                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-danger"
                    onClick={() => {
                        this.setState({
                            showDeleteModal: true
                        })

                    }}
                />
            </div>
        </>)
        return (
            <div className="editor-script">
                <div className="sideBar">
                    <DiagramEditorSideBar allDiagrams={this.state.allDiagrams}
                        prismicKeys={this.state.prismicKeys}
                        onDiagramSelection={(diagram) => {
                            this.setState({
                                diagram
                            })
                        }}
                        onNewDiagram={(diagramName, diagramImage) => {
                            const newDiagram = {
                                name: diagramName,
                                imageName: diagramImage,
                                dots: [],
                                legends: [],
                                imgSize: {
                                    width: 0,
                                    height: 0
                                }
                            }
                            this.diagramService.updateOne(newDiagram)
                                .then(() => {
                                    this._getDiagrams()
                                    this.setState({
                                        diagram: newDiagram
                                    })
                                })
                        }}
                    />
                </div>
                <Toolbar left={this.leftContents} right={this.rightContents} />


                {this.state.diagram && (
                    <div className="workPlace" id="diagramEditorWorkplace">
                        <Toast ref={(el) => (this.toast = el)} />
                        <FormDiagram diagram={this.state.diagram}
                            comments={this.state.allComments}
                            editable={true}
                            allPrismicKeys={this.state.prismicKeys}
                            mouseUp={(dots: DiagramDot[], width: number, height: number) => {
                                console.log(dots)
                                const diagram = this.state.diagram as any
                                diagram.dots = dots
                                diagram.imgSize = {
                                    width,
                                    height
                                }
                                this.setState({
                                    diagram: diagram
                                })
                            }}
                            onCommentSave={(comment: DiagramComment) => {
                                this.diagramCommentService.updateComment(comment)
                                    .then(() => {
                                        this.toast?.show({
                                            severity: SeverityEnum.SUCCESS,
                                            detail: "Commentaire enregistré !",
                                        });
                                        this._getComments()
                                    })
                                    .catch((err: any) => {
                                        const message = err?.response?.data?.message;
                                        this.toast?.show({
                                            severity: SeverityEnum.ERROR,
                                            detail: `Erreur : ${message}`,
                                        });
                                    })
                            }}
                            onCommentDelete={(number: number) => {
                                this.diagramCommentService.deleteComment(number)
                                    .then(() => {
                                        this.toast?.show({
                                            severity: SeverityEnum.SUCCESS,
                                            detail: "Commentaire supprimé !",
                                        });
                                        this._getComments()
                                    })
                                    .catch((err: any) => {
                                        const message = err?.response?.data?.message;
                                        this.toast?.show({
                                            severity: SeverityEnum.ERROR,
                                            detail: `Erreur : ${message}`,
                                        });
                                    })
                            }}
                            onLegendSave={(legend: DiagramLegend) => {
                                const diagram = this.state.diagram as any
                                diagram.legends.push(legend)
                                this.setState({
                                    diagram
                                })
                            }}
                            onLegendDelete={(title: string) => {
                                let diagram = this.state.diagram as any
                                diagram.legends = diagram.legends.filter(l => l.title !== title)
                                this.setState({
                                    diagram
                                })
                            }}
                        ></FormDiagram>
                    </div>
                )}

                {this.state.showDeleteModal && (
                    <Dialog
                        visible={this.state.showDeleteModal}
                        onHide={() => this.setState({ showDeleteModal: false })}
                        style={{ width: "600px" }}
                        header={"Voulez-vous vraiment supprimer ce schéma ?"}>
                        <Button
                            className="p-mr-5"
                            onClick={() => {
                                this.diagramService.deleteOne(this.state.diagram.name)
                                    .then(() => {
                                        this.toast?.show({
                                            severity: SeverityEnum.SUCCESS,
                                            detail: "Diagram supprimé !",
                                        });
                                        this.setState({
                                            showDeleteModal: false
                                        })
                                        this._getDiagrams()
                                    })
                                    .catch((err: any) => {
                                        const message = err?.response?.data?.message;
                                        this.toast?.show({
                                            severity: SeverityEnum.ERROR,
                                            detail: `Erreur : ${message}`,
                                        });
                                    })
                            }}>Oui</Button>
                        <Button className="p-button-danger"
                            onClick={() => {
                                this.setState({
                                    showDeleteModal: false
                                })
                            }}>Non</Button>
                    </Dialog>
                )}



            </div>
        );
    }
}

export default withTranslation()(DiagramEditor);
